import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Navabr.css";
import {
  Button,
  Img,
  Input,
  Line,
  List,
  Radio,
  RadioGroup,
  Text,
} from "components";
import Header from "components/Header";
import axios from "axios";
import { NavLink } from "react-router-dom";

const apiKey = process.env.REACT_APP_API_KEY;

const api = axios.create({
  baseURL: apiKey,
});
function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    // Close the model when the location changes
    closeModal();
  }, [location]);

  // function handleLocationClick(Location_name) {
  //   closeModal();
  //   navigate(`/escorts/${Location_name}`);
  // }

  const handleCity = () => {
    window.scrollTo(0, 0);
    navigate("/");
  };

  return (
    <>
      <div className="w-[100%] bg-black-900 mt-[-19px] ">
        <div className="bg-black-900 flex flex-col font-inter  justify-start mx-auto  pb-3">
          <div className="flex flex-col gap-8 custom8000:gap-3  md:gap-5 justify-start w-auto md:w-full">
            <Header
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              className="flex md:flex-row flex-row   items-center justify-between md:px-4 w-[100%]"
            />
            <div className="h-[100px] sm:h-[70px]"></div>

            <div className=" flex  justify-center hidden sm:flex">
              <div className="flex flex-col gap-[52px]    w-[93%] sm:w-[100%]">
                <div className="flex flex-row   justify-between flex-col md:px-5">
                  <div className="flex justify-between ">
                    <div
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                      className="mt-[-15px]  md:mt-0"
                    >
                      <Button
                        style={{ border: "solid 0.8px" }}
                        className="text-[17px]   flex px-1 py-1.5 text-white-A700 w-auto hover:text-pink-500  rounded-[5px]"
                      >
                        <Img
                          className="h-[25px] mr-[2px] "
                          src="/images/img_options.svg"
                          alt="options"
                        />
                        <div className="mt-[3px]">Popular Locations</div>
                      </Button>
                    </div>

                    <div className="flex gap-[25px] mb-3 mt-2 justify-between justify-start">
                      <Text
                        className="   text-[16px] text-white-A700 hover:text-pink-500 "
                        size="txtInterRegular18"
                      >
                        <Link to="/"> Home</Link>
                      </Text>

                      <Text
                        className="text-[16px] text-white-A700 w-auto hover:text-pink-500"
                        size="txtInterRegular18"
                      >
                        <Link to="/blog"> Blog</Link>
                      </Text>

                      {/* <NavLink to="City">
         <Text
            className="text-[16px] text-white-A700 w-auto hover:text-pink-500"
            size="txtInterRegular18"
          >
            Recently Viewed
          </Text>
         </NavLink> */}

                      {/* <div
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                      className="mt-[-15px] sm:hidden "
                    >
                      <Button
                        style={{ border: "solid 0.8px" }}
                        className="text-[17px]  flex px-5 py-2 text-white-A700 w-auto hover:text-pink-500  rounded-[5px]"
                      >
                        <Img
                          className="h-[30px] mr-[5px] "
                          src="/images/img_options.svg"
                          alt="options"
                        />
                        <div className="mt-[3px]">Popular Locations</div>
                      </Button>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          style={{ zIndex: "40", position: "absolute" }}
          className={`for_model top-[78px] sm:top-[53px]  ${
            isOpen ? "modal-animation" : ""
          }`}
          show={isOpen}
          onHide={closeModal}
          centered
        >
          <div className="flex flex-col font-inter items-center justify-start w-full ">
            <div
              style={{ overflow: "auto" }}
              className="bg-black-900 h-[100vh] flex flex-col md:gap-10 sm:gap-5 items-start  px-[40px]  sm:p-[25px]  sm:pt-[10px] w-full "
            >
              <div
                className="w-[100%] sm:hidden"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="top-[45px] sm:top-[0px]" onClick={closeModal} style={{ cursor: "pointer"  , position:"relative"}}>
                  <img src="/crossnav.png" alt="" />
                </div>
              </div>

              <h2
                className="my-[19px]  "
              
              >
                Popular Location for Escorts
              </h2>
              <div className=" navbar flex md:flex-col flex-row gap-[42px] items-start justify-start max-w-[1767px] mb-[17px] mx-auto w-full">
                <div className="flex flex-col gap-5 items-start justify-start w-auto">
                  <h4
                    
                  >
                    {
                      <NavLink to="/escorts/new-south-wales">
                        {" "}
                        New South Wales
                      </NavLink>
                    }
                  </h4>
                  <Text
                    className="text-base text-white-A700"
                    size="txtInterRegular16"
                  >
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Sydney")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/sydney">
                            {" "}
                            Sydney Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Kings Cross")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/kings-cross">
                            {" "}
                            Kings Cross Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("North Sydney")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/north-sydney">
                            {" "}
                            North Sydney Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Liverpool")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/liverpool">
                            {" "}
                            Liverpool Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Campbelltown")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/campbelltown">
                            {" "}
                            Campbelltown Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Bankstown")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/bankstown">
                            {" "}
                            Bankstown Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Home bush")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/home-bush">
                            {" "}
                            Homebush Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Parramatta")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/parramatta">
                            {" "}
                            Parramatta Escort
                          </NavLink>
                        }
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Penrith")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/penrith">
                            {" "}
                            Penrith Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Newcastle")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/newcastle">
                            {" "}
                            Newcastle Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Wollongong")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/wollongong">
                            {" "}
                            Wollongong Escort
                          </NavLink>
                        }
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Central Coast")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/central-coast">
                            {" "}
                            Central Coast Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Coffs Harbour")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/coffs-harbour">
                            {" "}
                            Coffs Harbour Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Forster")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/forster">
                            {" "}
                            Forster Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Byron Bay")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/new-south-wales/byron-bay">
                            {" "}
                            Byron Bay Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                        {
                          <NavLink to="/escorts/new-south-wales/tweed-heads">
                            {" "}
                            Tweed Heads Escort
                          </NavLink>
                        }{" "}
                      </div>

                      {/* <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/albury"> Albury  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/armidale"> Armidale  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/ballina"> Ballina  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/batemans-bay"> Batemans Bay  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/bathurst">Bathurst  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/blue-mountains">Blue Mountains  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/bourke">Bourke  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/broken-hill">Broken Hill  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/cooma">Cooma  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/cowra">Cowra  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/deniliquin">Deniliquin  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/dubbo">Dubbo  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/east-maitland">East Maitland  Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/forbes">Forbes  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/forster">Forster  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/glenn-innes">Glenn Innes  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/gosford"> Gosford  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/goulburn "> Goulburn   Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/grafton "> Grafton   Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/griffith "> Griffith  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/gunnedah "> Gunnedah  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/hunter-valley "> Hunter Valley  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/inverell "> Inverell  Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/ivanhoe ">  Ivanhoe  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/jindabyne ">  Jindabyne  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/katoomba ">   Katoomba  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/kempsey ">   Kempsey  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/kiama  ">  Kiama   Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/leeton  "> Leeton  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/lithgow  "> Lithgow  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/maitland  ">  Maitland  Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/merimbula  ">  Merimbula  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/moama  ">   Moama  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/moree  ">    Moree  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/mudgee  ">    Mudgee  Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/muswellbrook  ">    Muswellbrook  Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/narrabri-west  ">    Narrabri West  Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/newcastle  ">    Newcastle  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/north-lismore  ">    North Lismore  Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/nowra ">  Nowra  Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/orange ">   Orange Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/pambula ">   Pambula Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/parkes ">   Parkes Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/port-macquarie ">   Port Macquarie Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/queanbeyan ">  Queanbeyan Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/richmond-north ">  Richmond North Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/scone ">  Scone Escort</NavLink> }

                        {" "}
                      </div>

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/singleton ">  Singleton Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/south-grafton ">   South Grafton Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/tamworth ">   Tamworth Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/taree ">   Taree Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/tumut ">   Tumut Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/ulladulla ">   Ulladulla Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/wagga-wagga ">   Wagga Wagga Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/west-tamworth ">   West Tamworth Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/wilcannia ">   Wilcannia Escort</NavLink> }

                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/wollongong ">   Wollongong Escort</NavLink> }

                        {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/yamba ">   Yamba Escort</NavLink> }

                        {" "}
                      </div>

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Tweed Heads")}
                      >
                       
                        { <NavLink to= "/escorts/new-south-wales/young ">   Young Escort</NavLink> }

                        {" "}
                      </div> */}
                    </>
                  </Text>
                </div>
                <div className="flex flex-col gap-5 items-start justify-start w-auto">
                  <h4
                   
                  >
                    {
                      <NavLink to="/escorts/queensland">
                        {" "}
                        Queensland Escort
                      </NavLink>
                    }
                  </h4>
                  <Text
                    className="text-base text-white-A700"
                    size="txtInterRegular16"
                  >
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Brisbane")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/queensland/brisbane">
                            {" "}
                            Brisbane Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Gold Coast")}
                      >
                        {
                          <NavLink to="/escorts/queensland/gold-coast">
                            {" "}
                            Gold Coast Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Sunshine Coast")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/queensland/sunshine-coast">
                            {" "}
                            Sunshine Coast Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      {/* no */}
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Ipswich")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/queensland/ipswich">
                            {" "}
                            Ipswich Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Toowoomba")}
                      >
                        {
                          <NavLink to="/escorts/queensland/toowoomba">
                            {" "}
                            Toowoomba Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Bundaberg")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/queensland/bundaberg">
                            {" "}
                            Bundaberg Escort
                          </NavLink>
                        }
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Gladstone")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/queensland/gladstone">
                            {" "}
                            Gladstone Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Rockhampton")}
                      >
                        {
                          <NavLink to="/escorts/queensland/rockhampton">
                            {" "}
                            Rockhampton Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      {/* no */}
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("mackay")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/queensland/mackay">
                            {" "}
                            Mackay Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Townsville")}
                      >
                        {
                          <NavLink to="/escorts/queensland/townsville">
                            {" "}
                            Townsville Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Cairns")}
                      >
                        {
                          <NavLink to="/escorts/queensland/cairns">
                            {" "}
                            Cairns Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />

                      <div style={{ cursor: "pointer" }}>
                        {
                          <NavLink to="/escorts/queensland/mount-isa">
                            {" "}
                            Mount Isa Escort
                          </NavLink>
                        }{" "}
                      </div>

                      {/* 
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/airlie-beach">    Airlie Beach Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/atherton">   Atherton Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/ayr">  Ayr Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/barcaldine">  Barcaldine Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/bedourie">  Bedourie Escort</NavLink> }
                        {" "}
                      </div>


                      
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/biloela">  Biloela Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/birdsville">  Birdsville Escort</NavLink> }
                        {" "}
                      </div>

                      
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/bongaree">  Bongaree Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/boulia">  Boulia Escort</NavLink> }
                        {" "}
                      </div>



                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/bowen">  Bowen Escort</NavLink> }
                        {" "}
                      </div>




                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/burketown">  Burketown Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/caboolture">  Caboolture Escort</NavLink> }
                        {" "}
                      </div>

                      
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/camooweal">  Camooweal Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/charleville">  Charleville Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/charters-towers">  Charters Towers Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/chinchilla"> Chinchilla Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/cloncurry"> Cloncurry Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/coolum-beach"> Coolum beach Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/dalby"> Dalby Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/eidsvold"> Eidsvold Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/emerald"> Emerald Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/fraser-coast">  Fraser Coast Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/georgetown">  Georgetown Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/goondiwindi">  Goondiwindi Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/gympie">  Gympie Escort</NavLink> }
                        {" "}
                      </div>

                      
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/gympie-south">   Gympie South Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/hervey-bay">   Hervey Bay Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/hughenden">  Hughenden Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/innisfail">   Innisfail Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/karumba">   Karumba Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/kingaroy">   Kingaroy Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/logan">   Logan Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/longreach">   Longreach Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/mackay">   Mackay Escort</NavLink> }
                        {" "}
                      </div>
                      

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/maroochydore">   Maroochydore Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/mooloolaba">   Mooloolaba Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/moranbah">   Moranbah Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/mount-isa">   Mount Isa Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/nambour">   Nambour Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/noosa">   Noosa Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/north-mackay">   North Mackay Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/palm-cove">  Palm Cove Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/peregian">   Peregian Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/port-douglas">   Port Douglas Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/proserpine">  Proserpine Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/proserpine">  Proserpine Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/quilpie">  Quilpie Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/richmond">  Richmond Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/rockhampton">  Rockhampton Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/roma">  Roma Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/south-south-ingham  ">  South Ingham  Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/thargomindah  ">  Thargomindah  Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/theodore  ">  Theodore  Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/toowoomba  ">  Toowoomba  Escort</NavLink> }
                        {" "}
                      </div>

                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/townsville  ">  Townsville  Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/warwick  ">  Warwick  Escort</NavLink> }
                        {" "}
                      </div>


                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/weipa  ">  Weipa  Escort</NavLink> }
                        {" "}
                      </div>

                      
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/whitsundays  ">  Whitsundays  Escort</NavLink> }
                        {" "}
                      </div>

                      
                      
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/windorah  ">  Windorah  Escort</NavLink> }
                        {" "}
                      </div>

                        
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/winton  ">  Winton  Escort</NavLink> }
                        {" "}
                      </div>


                          
                      <br />
                      
                      <div
                        style={{ cursor: "pointer" }}
                    
                      >
                     
                        { <NavLink to= "/escorts/queensland/yeppoon">  Yeppoon  Escort</NavLink> }
                        {" "}
                      </div> */}
                    </>
                  </Text>
                </div>

                <div className="flex flex-col gap-5 items-start justify-start w-auto">
                  <h4
                  
                  >
                    {
                      <NavLink to="/escorts/western-australia">
                        {" "}
                        Western Australia Escorts
                      </NavLink>
                    }
                  </h4>
                  <Text
                    className="text-base text-white-A700"
                    size="txtInterRegular16"
                  >
                    <>
                      {" "}
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Perth")}
                      >
                        {
                          <NavLink to="/escorts/western-australia/perth">
                            {" "}
                            Perth Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Mandurah")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/western-australia/mandurah">
                            {" "}
                            Mandurah Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Rockingham")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/western-australia/rockingham">
                            {" "}
                            Rockingham escorts
                          </NavLink>
                        }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Bunbury")}
                      >
                        {
                          <NavLink to="/escorts/western-australia/bunbury">
                            {" "}
                            Bunbury Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Joondalup")}
                      >
                        {
                          <NavLink to="/escorts/western-australia/joondalup">
                            {" "}
                            Joondalup Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Fremantle")}
                      >
                        {
                          <NavLink to="/escorts/western-australia/fremantle">
                            {" "}
                            Fremantle Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Kalgoorlie")}
                      >
                        {
                          <NavLink to="/escorts/western-australia/kalgoorlie">
                            {" "}
                            Kalgoorlie Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Broome")}
                      >
                        {
                          <NavLink to="/escorts/western-australia/Broome">
                            {" "}
                            Broome Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                        {
                          <NavLink to="/escorts/western-australia/port-hedland">
                            {" "}
                            Port Hedland Escort
                          </NavLink>
                        }{" "}
                      </div>
                      {/* <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/albany">  Albany Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/broome">  Broome Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/busselton">Busselton  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/carnarvon">Carnarvon  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/esperance"> Esperance Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>






                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/exmouth">Exmouth  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/geraldton">Geraldton  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/gingin"> Gingin Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>




                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/halls-creek"> Halls Creek Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>




                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/kalbarri">Kalbarri   Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/karratha">Karratha  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/katanning"> Katanning Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>




                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/kununurra"> Kununurra Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/kwinana"> Kwinana Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>




                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/laverton">Laverton  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/leonora"> Leonora Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/mandurah">  Mandurah Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>




                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/manjimup">Manjimup   Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/meekatharra">Meekatharra  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/merredin">Merredin  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/morawa"> Morawa Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/mount-barker">Mount Barker  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/mount-magnet"> Mount Magnet Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/narrogin "> Narrogin  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/newman"> Newman Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/norseman">Norseman  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/northam">Northam  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/onslow">Onslow  Escort</NavLink> }

                        {" "}
                        {" "}
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/pannawonica"> Pannawonica Escort</NavLink> }
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/port-denison"> Port Denison Escort</NavLink> }
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/port-hedland"> Port Hedland  Escort</NavLink> }
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/ravensthorpe">Ravensthorpe  Escort</NavLink> }
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/roebourne"> Roebourne  Escort</NavLink> }
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/southern-cross">Southern Cross  Escort</NavLink> }
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/three-springs">Three Springs  Escort</NavLink> }
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/tom-price"> Tom Price  Escort</NavLink> }
                      </div>


                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Port Hedland")}
                      >
                                                { <NavLink to= "/escorts/western-australia/wagin"> Wagin  Escort</NavLink> }
                      </div> */}
                    </>
                  </Text>
                </div>

                <div className="flex flex-col gap-5 items-start justify-start w-auto">
                  <h4
                   
                  >
                    {<NavLink to="/escorts/victoria"> Victoria Escort</NavLink>}
                  </h4>
                  <Text
                    className="text-base text-white-A700"
                    size="txtInterRegular16"
                  >
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        // // onClick={() => handleLocationClick("Melbourne")}
                      >
                        {
                          <NavLink to="/escorts/victoria/melbourne">
                            {" "}
                            Melbourne Escort
                          </NavLink>
                        }
                      </div>
                      <br />
                      {/* no */}
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("StKilda")}
                      >
                        {
                          <NavLink to="/escorts/victoria/StKilda">
                            St Kilda Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Geelong")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/victoria/geelong">
                            {" "}
                            Geelong Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Ballarat")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/victoria/ballarat">
                            {" "}
                            Ballarat Escort
                          </NavLink>
                        }{" "}
                      </div>

                      {/* <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/ararat"> Ararat Escort</NavLink> }
                       {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/bairnsdale-east"> Bairnsdale East Escort</NavLink> }
                       {" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/bellarine-peninsula"> Bellarine Peninsula Escort</NavLink> }
                       {" "}
                      </div>



                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/bendigo"> Bendigo  Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/cobram ">  Cobram  Escort</NavLink> }
                       {" "}
                      </div>




                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/colac"> Colac Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/cranbourne"> Cranbourne Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/drysdale"> Drysdale Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/echuca">  Echuca  Escort</NavLink> }
                       {" "}
                      </div>




                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/hamilton"> Hamilton Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/horsham">Horsham  Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/lakes-entrance">Lakes Entrance  Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/maryborough"> Maryborough Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/melton"> Melton Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/mildura"> Mildura  Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/mornington-peninsula">Mornington Peninsula  Escort</NavLink> }
                       {" "}
                      </div>




                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/ouyen">Ouyen  Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/pakenham "> Pakenham   Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/portland"> Portland Escort</NavLink> }
                       {" "}
                      </div>





                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/sale">Sale  Escort</NavLink> }
                       {" "}
                      </div>




                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/seymour ">Seymour   Escort</NavLink> }
                       {" "}
                      </div>

                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/shepparton">Shepparton  Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/mouth-melbourne">South Melbourne  Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/stawell"> Stawell Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/sunbury "> Sunbury   Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/swan-hill"> Swan Hill Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/traralgon">Traralgon  Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/wangaratta">Wangaratta  Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/warrnambool"> Warrnambool  Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/wodonga">Wodonga  Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/wonthaggi">Wonthaggi  Escort</NavLink> }
                       {" "}
                      </div>
                      

                      
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        { <NavLink to= "/escorts/victoria/yarrawonga"> Yarrawonga  Escort</NavLink> }
                       {" "}
                      </div> */}
                    </>
                  </Text>
                </div>
                <div className="flex flex-col gap-5 items-start justify-start w-auto">
                  <h4
                    
                  >
                    {
                      <NavLink to="/escorts/south-australia">
                        SA - South Australia
                      </NavLink>
                    }
                  </h4>
                  <Text
                    className="text-base text-white-A700"
                    size="txtInterRegular16"
                  >
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Adelaide")}
                      >
                        {
                          <NavLink to="/escorts/south-australia/adelaide">
                            Adelaide Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Glenelg")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/south-australia/glenelg">
                            {" "}
                            Glenelg Escort
                          </NavLink>
                        }{" "}
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Flagstaff Hill")}
                      >
                        {
                          <NavLink to="/escorts/south-australia/flagstaff-hill">
                            Flagstaff Hill Escort
                          </NavLink>
                        }
                      </div>

                      {/* <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/andamooka">Andamooka  Escort</NavLink> }
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/berri"> Berri  Escort</NavLink> }
                      </div>

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/bordertown"> Bordertown  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/ceduna"> Ceduna Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/clare"> Clare  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/coober-pedy">Coober Pedy  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/cowell">Cowell  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/gawler"> Gawler  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/kimba">Kimba  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/kingoonya">Kingoonya  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/kingston South East">Kingston South East  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/meningie">Meningie  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/mount-gambier">Mount Gambier  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/murray-bridge"> Murray Bridge Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/penola"> Penola  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/peterborough">Peterborough  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/port-augusta-west">Port Augusta West  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/port-lincoln">Port Lincoln  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/port-pirie"> Port Pirie Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/renmark"> Renmark  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/streaky-bay"> Streaky Bay  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/tumby-Bay"> Tumby Bay  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/victor-harbor">Victor Harbor  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/wallaroo">Wallaroo  Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/whyalla ">Whyalla   Escort</NavLink> }
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                      >
                                                { <NavLink to= "/escorts/south-australia/woomera">Woomera  Escort</NavLink> }
                      </div> */}
                    </>
                  </Text>
                </div>

                <div className="flex flex-col gap-5 items-start justify-start w-auto">
                  <h4
                   
                  >
                    {
                      <NavLink to="/escorts/northern-territory">
                        {" "}
                        NT-Northern Territory{" "}
                      </NavLink>
                    }
                  </h4>
                  <Text
                    className="text-base text-white-A700"
                    size="txtInterRegular16"
                  >
                    <>
                      {" "}
                      <div style={{ cursor: "pointer" }}>
                        {" "}
                        {
                          <NavLink to="/escorts/northern-territory/darwin">
                            {" "}
                            Darwin Escort{" "}
                          </NavLink>
                        }

                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Katherine")}
                      >
                        {
                          <NavLink to="/escorts/northern-territory/katherine">
                            {" "}
                            Katherine Escort
                          </NavLink>
                        }{" "}
                      </div>
                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Alice Springs")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/northern-territory/alice-springs">
                            {" "}
                            Alice Springs Escort
                          </NavLink>
                        }{" "}
                      </div>
                      {/* <br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/northern-territory/adelaide-river"> Adelaide River Escort</NavLink> }
 {" "}
</div>

                      
<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/northern-territory/mcminns-lagoon">McMinns Lagoon Escort</NavLink> }
 {" "}
</div>

                      
<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/northern-territory/palmerston-em">  Palmerston em  Escort</NavLink> }
 {" "}
</div>

                      
<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/northern-territory/pine-creek"> Pine Creek Escort</NavLink> }
 {" "}
</div>

                      
<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/northern-territory/Yulara"> Yulara Escort</NavLink> }
 {" "}
</div> */}
                    </>
                  </Text>
                </div>
                <div className="flex flex-col gap-5 items-start justify-start w-auto">
                  <h4
                   
                  >
                    {<NavLink to="/escorts/tasmania"> Tasmania</NavLink>}
                  </h4>
                  <Text
                    className="text-base text-white-A700"
                    size="txtInterRegular16"
                  >
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Hobart")}
                      >
                        {
                          <NavLink to="/escorts/tasmania/hobart">
                            {" "}
                            Hobart Escort
                          </NavLink>
                        }{" "}
                      </div>

                      {/* <br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/bicheno"> Bicheno Escort</NavLink> }
 {" "}
</div> */}

                      {/* <br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/burnie"> Burnie  Escort</NavLink> }
 {" "}
</div> */}
                      {/* 
<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/currie"> Currie  Escort</NavLink> }
 {" "}
</div>

<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/devonport">Devonport  Escort</NavLink> }
 {" "}
</div>


<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/kingston-beach">Kingston Beach  Escort</NavLink> }
 {" "}
</div>


<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/north-scottsdale">North Scottsdale  Escort</NavLink> }
 {" "}
</div>


<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/oatlands">Oatlands  Escort</NavLink> }
 {" "}
</div>


<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/queenstown">Queenstown  Escort</NavLink> }
 {" "}
</div>


<br />
<div
  style={{ cursor: "pointer" }}
>
  {" "}
  { <NavLink to= "/escorts/tasmania/smithton"> Smithton  Escort</NavLink> }
 {" "}
</div> */}

                      <br />
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Launceston")}
                      >
                        {" "}
                        Launceston Escort{" "}
                      </div>
                    </>
                  </Text>
                </div>

                <div className="flex flex-col gap-5 items-start justify-start w-auto">
                  <h4
                   
                  >
                    {
                      <NavLink to="/escorts/australian-capital-territory">
                        {" "}
                        Australian Capital Territory
                      </NavLink>
                    }
                  </h4>
                  <Text
                    className="text-base text-white-A700"
                    size="txtInterRegular16"
                  >
                    <>
                      {" "}
                      <div
                        className="sm:pb-10"
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleLocationClick("Canberra")}
                      >
                        {" "}
                        {
                          <NavLink to="/escorts/australian-capital-territory/canberra">
                            {" "}
                            Canberra Escort
                          </NavLink>
                        }{" "}
                      </div>
                    </>
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {/* <div className="h-[150px]"></div> */}
    </>
  );
}

export default Navbar;
