// userReducer.js
const initialState = {
  userName: "",
  isLogging: false,
  email: "",
  role: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "User_Data":
      return {
        ...state,
        userName: action.payload.userName,
        isLogging: action.payload.isLogging,
        email: action.payload.email,
        role: action.payload.role,
      };

    case "LOGOUT_USER":
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
