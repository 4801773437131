import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";


// import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Img, Text } from "components";
import Loader from "components/Loader/Loader";

import NotFound from "pages/NotFound";
import CountryPage from "pages/CountryPage";
import MainHomePage from "pages/MainHomePage";
const Membershipplan = React.lazy(() => import("pages/Membershipplan"));
const Searchpage = React.lazy(() => import("pages/Searchpage"));
const ContactUs = React.lazy(() => import("pages/ContactUs"));
const Myprofilesidebaar = React.lazy(() => import("pages/Myprofilesidebaar"));
const Registration = React.lazy(() => import("pages/Registration"));
const Blog = React.lazy(() => import("pages/Blog"));
const AddaProfile = React.lazy(() => import("pages/AddaProfile"));
const PremiumEscape = React.lazy(() => import("pages/PremiumEscape"));
const AgencyListPAge = React.lazy(() => import("pages/AgencyListPAge"));
const DetaliedListingPage = React.lazy(() =>
  import("pages/DetaliedListingPage")
);

const State = React.lazy(() => import("pages/State"));
// import { City } from "pages/city";
const Homepage = React.lazy(() => import("pages/Homepage"));
const Frontpage = React.lazy(() => import("pages/Frontpage"));
const Frame152 = React.lazy(() => import("pages/Frame152"));
const Popular_Locations = React.lazy(() =>
  import("./pages/Popular_Location_pages/Popular_Locations")
);
const Faqs_page = React.lazy(() => import("./pages/Self_pages/Faqs_page"));
const Login = React.lazy(() => import("pages/Login"));
const Banner = React.lazy(() => import("pages/Banner"));
const BlogPerPage = React.lazy(() => import("pages/BlogPerPage"));
const ScrollTop = React.lazy(() => import("./ScrollTop/ScrollTop"));
const ModelDashboard = React.lazy(() =>
  import("./pages/Deshboards/ModelDashBoards/modelDashboard")
);
const AdminDashboard = React.lazy(() =>
  import("./pages/Deshboards/AdminDashboard/adminDashBoard")
);
const AdminProtected = React.lazy(() =>
  import("./protectedRoutes/AdminProtected")
);
const ModelProtected = React.lazy(() =>
  import("./protectedRoutes/ModelProtected")
);
const ModelProtect = React.lazy(() => import("./protectedRoutes/ModelProtect"));
const ResetPass = React.lazy(() => import("./pages/ResetPass/index"));
const Verify = React.lazy(() => import("./VerifyEmail/index"));
const Documention = React.lazy(() => import("./pages/Documention/index"));
const Replacement = React.lazy(() => import("./pages/Replacement"));
const Term_and_conditions = React.lazy(() =>
  import("./pages/Term_and_conditions/index")
);
const SiteMap = React.lazy(() => import("./pages/SiteMap/index"));
const Privacy_Policy = React.lazy(() =>
  import("./pages/Privacy_And_policy/index")
);
const DMCA = React.lazy(() => import("./pages/DMCA/index"));

const ProjectRoutes = () => {
  const [showPopup, setShowPopup] = useState(false);


  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");

    if (!hasVisited) {
      setShowPopup(true);
    }
  }, [window.location.pathname]);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const { id } = useParams();

  const handleClickGo = () => {
    window.location.href = window.location.pathname;
    localStorage.setItem("hasVisited", "true");
    // setShowPopup(false)
  };

  

  
 

  return (
    <React.Suspense fallback={<>{Loader}</>}>
      <Router>
        {/* <Helmet>
          <link rel="canonical" href={canonical} />
        </Helmet> */}
        <ScrollTop />
        <Routes>
          {/* <Route path="*" element={<NotFound />} /> */}

          <Route path="/homepage" element={<MainHomePage />} />
          <Route path="/" element={<Homepage />} />

          {/* <Route
            path="/detaliedlistingpage"
            element={<DetaliedListingPage />}
          /> */}
          <Route path="/profile/:name/:id" element={<DetaliedListingPage />} />
          {/* <Route path="/agencylistpage"  element={<AgencyListPAge />} /> */}
          {/* <Route path="/premiumEscort" element={<PremiumEscape />} /> */}

          <Route path="/blog" element={<Blog />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/searchpage" element={<Searchpage />} />
          <Route path="/popular-locations" element={<Popular_Locations />} />
          {/* <Route path="/Faqs_page" element={<Faqs_page/>} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/escorts/:country/:state" element={<State />} />
          <Route path="/escorts/:statec" element={<CountryPage />} />
          <Route path="/blog/:name/:id" element={<BlogPerPage />} />
          <Route path="/reset-password" element={<ResetPass />} />
          <Route path="/Verify" element={<Verify />} />
          <Route path="/about-us" element={<Documention />} />
          <Route path="/replacement" element={<Replacement />} />
          <Route
            path="/term-and-conditions"
            element={<Term_and_conditions />}
          />
          <Route path="/site" element={<SiteMap />} />
          <Route path="/privacy-and-policy" element={<Privacy_Policy />} />
          <Route path="/dmca" element={<DMCA />} />

          <Route
            path="/addaprofile"
            element={<ModelProtect element={AddaProfile} />}
          />
          {/* <Route path="/addaprofile" element={<AddaProfile />} /> */}

          {/* <Route path="/myprofilesidebaar" element={<ModelProtect element={Myprofilesidebaar}/> } /> */}
          {/* <Route path="/myprofilesidebaar" element={<Myprofilesidebaar />} /> */}

          {/* <Route path="/membershipplan" element={<ModelProtect element={Membershipplan}/> } /> */}

          {/* <Route path="/modelDashboard" element={<ModelDashboard />} />  */}
          {/* <Route path="/AdminDashboard" element={<AdminDashboard />} />  */}
          <Route
            path="/AdminDashboard/:navName"
            element={<AdminProtected component={AdminDashboard} />}
          />
          <Route
            path="/modelDashboard"
            element={<ModelProtected component={ModelDashboard} />}
          />
        </Routes>
      </Router>

     {

  window.location.pathname === "/term-and-conditions" ?"": showPopup && (
    <div  className="overlay ">
      <div style={{position:"absolute" , zIndex:"-1" ,  height:"100vh" , width:"100%" ,    backgroundColor: "#040404db",  }}>

      </div>
      <div style={{ justifyContent:"center" }} className="popup11 flex animate__animated animate__fadeInDown  sm:block ">
        <div  className=" font-inter h-[100vh] mx-auto pb-1 pl-1 relative w-[1000px]  md:w-[70%] custom8000:w-[85%] sm:w-[95%]">
        
          {/* <Img
            className="absolute sm:h-11 md:h-12 h-[61px] left-[7.5%] sm:left-[27px] md:left-[9.4%] object-cover sm:top-[5%] top-[7%]  "
            src="/images/img_logo1.svg"
            alt="logoOne"
          /> */}
          <div style={{backgroundColor:"rgb(18 18 18 / 92%)" , borderRadius:"20px" , position:"relative" }} className=" top-[35%] sm:top-[25%]  flex flex-col md:gap-10 gap-[120px]   md:p-[]  w-[100%] py-[80px] px-[20px]">
            <div className=" md:border-[] md:border-b-[] md:border-b-colors md:border-colors md:border-l-[] md:border-l-colors md:border-r-[] md:border-r-colors md:border-t-[] md:border-t-colors flex flex-col  items-start md:items-start justify-start md:justify-start w-auto md:w-full">
              <Text
                className="font-medium  sm:text-3xl md:text-4xl text-[44px]  text-white-A700 w-auto"
                size="txtInterSemiBold60"
              >
                Hot Content website
              </Text>
           
              <Text className="font-semibold  text-[19px] text-white-A700    ">
                {" "}
                WARNING: This Website Contains Adult Content. You Must Be <strong> 18
                Year's </strong> of Age or Older to Access.
              </Text>
              <div className="flex flex-row gap-7  items-start justify-start w-auto mt-5">
                <Button
                  style={{ backgroundColor: "#E80D71", color: "#ffffff" }}
                  className="common-pointer  cursor-pointer font-semibold sm:h-[49px] h-[50px] rounded-[30px] sm:text-base text-center text-lg sm:w-[133px] w-[149px]"
                 onClick={()=>{
                  handleClickGo()
                 }}
                  size="md"
                  variant="fill"
                >
                  Enter
                </Button>
                <Button
                  style={{ border: "solid #E80D71", color: "#E80D71" }}
                  className="  !text-colors1 md:bg-colors border-solid cursor-pointer font-semibold sm:h-12 h-[50px] rounded-[30px] text-center text-lg sm:w-[133px] w-[149px]"
                  size="md"
                  variant="outline"
                  onClick={() =>
                    (window.location.href = "https://www.google.com")
                  }
                >
                  Exit
                </Button>
              </div>
              <Text
                className="text-[16px] text-white-A700_7f  mt-5"
                size="txtInterRegular18WhiteA7007f"
              >
                {" "}
                By clicking <span style={{cursor:"pointer " , textDecoration:"underline"}}  onClick={()=>{
                  handleClickGo()
                 }}> "I AGREE AND ENTER" </span> below, you confirm  
                <strong> you are 18 years's of age </strong> or older and you have read and accepted
                our <a style={{textDecoration:"underline"}} href="/term-and-conditions">Terms and Condition</a>
              </Text>
              <Text
              className="text-lg text-white-A700 w-auto mt-5"
              size="txtInterRegular18"
            >
              © 2023 velvet klub. all rights reserved.
            </Text>
            
            </div>

           
          </div>
        </div>
        {/* <button onClick={handlePopupClose}>Close</button> */}
      </div>
    </div>
  )}

     
    </React.Suspense>
  );
};
export default ProjectRoutes;
