
import "./index.css"
const Loader = ()=>{

    return(
        <>

        <div style={{  position:"fixed" , top:"0px"  , zIndex:"9999999999" , overflow:"hidden"}} className="w-[100%] h-[100vh] backdrop-blur-sm">
        <main className="main">
  {/* <p className="text">
    VelvetKlub Loading
    <span className="preloader"></span>
  </p> */}
  <img className="h-[80%] sm:h-[35%] " src="/images/imgpsh_fullsize_anim.gif" alt="" />
</main>

        </div>
        </>
    )
}
export default Loader





// const Loader = () => {
  

//   return (
//    <div style={{zIndex:"1000" , position:"absolute" , top:"0px" , }} >
  
//   <main className="main">
//   <p className="text">
//     Amazon realty Loading
//     <span className="preloader"></span>
//   </p>
// </main>

//    </div>
//   );
// };

// export default Loader;
