import "./index.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Navbar from "pages/Self_pages/Navbar";
import Footer from "pages/Self_pages/Footer";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "components/Loader/Loader";

const apiKey = process.env.REACT_APP_API_KEY;

const api = axios.create({
  baseURL: apiKey,
});

const CountryPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  let { statec } = useParams();
  statec = statec.replaceAll("-", " ").toLowerCase().replaceAll(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
  // console.log(statec);
  useEffect(() => {
    // // console.log(requestData1);

    const requestData = { state: statec };

    setLoading(true);
    api
      .get("/api/search-models", {
        params: requestData,
      })
      .then((response) => {
        setLoading(false);
        // Handle the response data here
        // // console.log(response.data.data);
        setData(response.data.data);
      })
      .catch((error) => {
        // Handle any errors here
        setLoading(false);
        console.error(error);
      });
    // setLoading(true);
    // api
    //   .get("/api/get-pages", {
    //     params: { name: state },
    //   })
    //   .then((response) => {
    //     setLoading(false);
    //     // Handle the response data here

    //     setShowData(response.data);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     // Handle any errors here
    //     console.error(error);
    //   });
  }, [statec]);

  function extractTextFromHTML(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  }

  // const handleModelsClick = (value) => {
  //   navigate(`/detaliedlistingpage/?model=${value}`);
  // };

  const handleModelsClick = (id, name, county) => {
    const lowercaseName = name.toLowerCase();
    const encodedTitle = encodeURIComponent(lowercaseName).replaceAll(/%20/g, "-");
    const lowercasecounty = county.toLowerCase();
    const encodedCounty = encodeURIComponent(lowercasecounty).replaceAll(
      /%20/g,
      "-"
    );
    navigate(`/profile/${encodedTitle}/${id}?state=${encodedCounty}`);
    // navigate(`/profile/${encodedTitle}/${id}`   );
  };

  // const handleModelsClick = (id , name) => {
  //   const encodedTitle = encodeURIComponent(name).replace(/%20/g, '-');
  //   navigate(`/profile/${encodedTitle}/${id}`   );
  // };

  return (
    <>
      {/* {showData && showData[0] && (
        <Helmet>
          <title> {showData[0].meta_title} </title>

          <meta name="description" content={showData[0].meta_description} />
         
        </Helmet>
      )} */}

      <div className="">
        {" "}
        <Navbar />{" "}
      </div>

      <div className="page ">
        <div className="jss1 mb">
          <div className="jss51 jss53">
            <div className="jss52">
              <div className="jss32">
                {/* <div className="jss55">
                  <h2 className="MuiTypography-root jss57  MuiTypography-h4  ">
                    Premium and Private Escorts in {statec}
                  </h2>
                  <div className="jss59 ">
                    <p>
                      Take a look at our verified featured escorts from all
                      around
                      {statec}. Sexy, real and authentic — that's the Velvet way.
                    </p>
                  </div>
                </div> */}

                <div className="jss37 mt-3">
                  {data &&
                    data[0] &&
                    data.map((i, index) => {
                      const htmlContent = i && i.model && i.model.description;
                      const textContent = extractTextFromHTML(htmlContent);
                      // console.log(i);

                      return (
                        <>
                          <div
                            onClick={() =>
                              handleModelsClick(i.model.id,  i.name, i.profile.state)
                            }
                            className="jss35"
                          >
                            <NavLink to="/">
                              {" "}
                              <div className="jss78">
                                <div className="jss79 jss93">
                                  <div className="jss89">
                                    <div
                                      className="jss97 follow-user"
                                      role="button"
                                    >
                                      <button
                                        className="MuiButtonBase-root MuiIconButton-root jss90"
                                        type="button"
                                        aria-label="Follow Alanna Akira"
                                      >
                                        <span className="MuiIconButton-label">
                                          <svg
                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
                                            focusable="false"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                          >
                                            <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z" />
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      overflow: "hidden",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                    }}
                                  >
                                    <img
                                      title=""
                                      alt=""
                                      //  src={`https://velvetklub.com/images/${i && i.model && i.model.image_name}`}

                                      src={`${apiKey}images/${
                                        i && i.model && i.model.image_name
                                      }`}
                                      decoding="async"
                                      data-nimg="fill"
                                      className="jss81"
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        boxSizing: "border-box",
                                        padding: 0,
                                        border: "none",
                                        margin: "auto",
                                        display: "block",
                                        width: 0,
                                        height: 0,
                                        minWidth: "100%",
                                        maxWidth: "100%",
                                        minHeight: "100%",
                                        maxHeight: "100%",
                                      }}
                                    />
                                  </span>
                                </div>
                                <figcaption className="jss85">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h4 className="MuiTypography-root name MuiTypography-body1">
                                      {i && i.name && i.name}
                                    </h4>
                                    <div
                                      theme="[object Object]"
                                      className="jss98 jss99"
                                      show={0}
                                      title="Recently online"
                                    />
                                  </div>
                                  <h6 className="MuiTypography-root subheading MuiTypography-subtitle2">
                                    {i && i.model && i.model.age}
                                 
                                    { ','+ i && i.profile && i.model.suburb},
                                    {i && i.profile && i.profile.country}
                                  </h6>
                                  <p className="MuiTypography-root jss88 MuiTypography-subtitle2 extraDivCity">
                                    {textContent}
                                  </p>
                                  <div
                                    className="jss55"
                                    style={{ marginBottom: 0, marginTop: 0 }}
                                  >
                                    <span className="MuiTypography-root jss57 jss58 MuiTypography-body1">
                                      Velvet
                                    </span>
                                  </div>
                                </figcaption>
                              </div>
                            </NavLink>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="MuiBox-root jss49 jss41 ">
            <div className="MuiBox-root jss50 jss42 ">
              <h1 className="MuiTypography-root jss34 MuiTypography-h1 capitalize">
                {statec}
                
              </h1>
              <div className="htmlData">
                {" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      showData &&
                      showData[0] &&
                      showData[0].content &&
                      showData[0].content,
                  }}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div>
        {" "}
        <Footer />{" "}
      </div>

      {loading ? <Loader /> : ""}
    </>
  );
};

export default CountryPage;
