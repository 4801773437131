import { Link, NavLink } from "react-router-dom";
import {
  Button,
  Img,
  Input,
  Line,
  List,
  Radio,
  RadioGroup,
  Text,
} from "components";

function Footer() {
  return (
    <>
      <div className="bg-black-900  flex flex-col font-inter items-center justify-start mx-auto py-[18px] w-full">
        <div className="flex flex-col items-center justify-start mb-[21px] mt-[91px] sm:mt-1 mx-auto md:px-5 w-full">
          <div className="flex md:flex-col flex-row md:gap-10 gap-[119px] items-start justify-between w-[93%] md:w-full">
            <List
              className="sm:flex-col flex-row md:gap-10 gap-[119px] sm:gap-[20px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 w-[100%] md:w-full"
              orientation="horizontal"
            >

<div className="flex flex-col gap-6 sm:gap-1 items-start justify-start w-auto">
                <h3
                  
                  
                >
               Popular Cities
                </h3>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/new-south-wales/sydney">
                    {" "}
                    Sydney Escorts
                  </NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/victoria/melbourne"> Melbourne  Escorts</NavLink>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/queensland/brisbane"> Brisbane  Escorts</NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/western-australia/perth">
                    {" "}
                    Perth  Escorts
                  </NavLink>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/south-australia/adelaide">
                    Adelaide Escorts
                  </NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/queensland/gold-coast">
                    {" "}
                    Gold Coast Escorts
                  </NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/new-south-wales/newcastle">
                    {" "}
                    Newcastle Escorts
                  </NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/australian-capital-territory/canberra">
                    {" "}
                    Canberra Escorts
                  </NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/queensland/sunshine-coast">
                    {" "}
                    Sunshine Coast Escorts
                  </NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/victoria/geelong"> Geelong Escorts</NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/tasmania/hobart"> Hobart Escorts</NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/escorts/queensland/cairns"> Cairns Escorts</NavLink>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <NavLink to="/popular-locations"> View All Cities</NavLink>
                </Text>


              </div>












              <div className="flex flex-col gap-6 sm:gap-1 items-start justify-start w-auto">
                <h3
                >
                 Popular Categories
                </h3>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="">BDSM Escorts</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Strip Dance Escorts</Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Independents Escorts</Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Female Escorts</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Male Escorts</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Transsexual Escorts </Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Transgender Escorts </Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Anal Sex Escorts </Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Anal Play Escorts </Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Couples Escorts </Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> View All </Link>
                </Text>

               
              </div>
        






              <div className="flex flex-col gap-6 sm:gap-1 items-start justify-start w-auto">
                <h3
                 
                >
                Popular Reviews
                </h3>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Sydney Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Melbourne Review</Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Brisbane Review</Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Perth Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Adelaide Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Gold Coast Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Newcastle Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Canberra Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Sunshine Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Geelong Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Hobart Review</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escorts Cairns Review</Link>
                </Text>
             
           

               
              </div>






{/* 
              <div className="flex flex-col gap-6 sm:gap-1 items-start justify-start w-auto">
                <Text
                  className="text-white-A700 text-xl w-[179px] sm:text-[18px]"
                  size="txtInterBold20"
                >
                 About us
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/"> Home</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/searchpage"> Search</Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/blog"> Blog</Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/site"> Sitemap</Link>
                </Text>


              </div> */}
        

              <div className="flex flex-col gap-6 sm:gap-1 items-start justify-start w-auto">
                <h3
                  
                >
                 Resources
                </h3>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/privacy-and-policy"> Privacy Policy</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/registration"> How to Submit Your Profile</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/replacement">Delivery/Refund Policy</Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-[220px] "
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/popular-locations">
                    {" "}
                    Popular Locations for Escort
                  </Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/dmca"> DMCA Notice</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/about-us"> Documentation</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/contactus"> Contact Us</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Pricing</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/term-and-conditions"> Terms and Conditions</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/about-us"> About us </Link>
                </Text>
                
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to=""> Escort Announcements</Link>
                </Text>
                
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/about-us"> FAQ</Link>
                </Text>
                
                
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/blog"> Blog & Escort Journals</Link>
                </Text>
                
                {/* <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/popular-locations"> All Location</Link>
                </Text> */}
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/site">Sitemap</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="">Velvet Klub New Zealand</Link>
                </Text>
              </div>
              {/* <div className="flex flex-col gap-6 items-start justify-start w-auto">
                <Text
                  className="text-white-A700 text-xl w-[179px] sm:text-[18px]"
                  size="txtInterBold20"
                >
                  Help & Contact
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/about-us"> Documentation</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/contactus"> Contact Us</Link>
                </Text>

                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/term-and-conditions"> Terms and Conditions</Link>
                </Text>
                <Text
                  className="text-lg text-white-A700_7f w-auto"
                  size="txtInterRegular18WhiteA7007f"
                >
                  <Link to="/privacy-and-policy"> Privacy Policy</Link>
                </Text>
              </div> */}
            
              
            </List>
            {/*         
            <div className="flex flex-col gap-5 items-start justify-start w-auto sm:w-full">
              <Text
                className="text-lg text-white-A700_7f"
                size="txtInterRegular18WhiteA7007f"
              >
                <>
                  Subscribe For our Newsletters and stay updated <br />
                  About the latest news and Special Offers.
                </>
              </Text>
              <div className=" border border-solid border-white-A700 flex flex-row items-center justify-between sm:pl-5 pl-8 rounded-[5px] w-[93%] md:w-full">
                <Input
                 
                  className="p-0  placeholder:text-white-A700_7a sm:px-5 text-left text-lg text-pink-500 w-full text-pink-500"
                />
                 
                
                <Text
                  className="bg-pink-500 border border-pink-500 border-solid justify-center sm:pl-5 pl-8 pr-[18px] py-[10px] rounded-br-[5px] rounded-tr-[5px] text-lg text-white-A700 w-auto"
                  size="txtInterRegular18"
                >
                 Subscribe
                </Text>
              </div>
            </div> */}
          </div>
          <Line className="bg-white-A700_4f h-px mt-[52px] w-full" />
          <div className="flex sm:flex-col flex-row md:gap-10  items-center justify-between mt-[46px] w-[93%] md:w-full">
            <Text
              className="text-lg text-white-A700_7f w-auto"
              size="txtInterMedium18"
            >
              © 2023 velvet klub. all rights reserved.
            </Text>

            <div className="flex flex-col items-center justify-start w-auto">
              <div className="flex flex-row gap-6 items-center justify-start w-auto">
                <Button className="border border-pink-500 border-solid flex h-12 items-center justify-center p-3.5 rounded-[50%] w-12">
                  <a href="https://www.facebook.com/profile.php?id=61550266074187">
                    <Img
                      className="h-5"
                      src="/images/img_ggfacebook.svg"
                      alt="ggfacebook"
                    />
                  </a>
                </Button>
                <Button className="border border-pink-500 border-solid flex h-12 items-center justify-center p-3.5 rounded-[50%] w-12">
                  <a href="https://www.instagram.com/velvetklub/">
                    <Img
                      className="h-5"
                      src="/images/img_riinstagramfill.svg"
                      alt="riinstagramfill"
                    />
                  </a>
                </Button>
                <Button className="border border-pink-500 border-solid flex h-12 items-center justify-center p-3.5 rounded-[50%] w-12">
                  <a href="https://twitter.com/velvetklub">
                    <Img src="/images/img_twitter.svg" alt="twitter" />
                  </a>
                </Button>

                <Button className="border border-pink-500 border-solid flex h-12 items-center justify-center p-3.5 rounded-[50%] w-12">
                  <a href="https://www.pinterest.com.au/velvetklubofficial/">
                    <Img src="/images/pss.png" alt="" className="h-5 w-5" />
                  </a>
                </Button>
                <Button className="border border-pink-500 border-solid flex h-12 items-center justify-center p-3.5 rounded-[50%] w-12">
                  <a href="https://www.tumblr.com/velvetklub">
                    <Img src="/images/tss.png" alt="" className="h-5 w-5" />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
